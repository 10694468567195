import CheckboxSelectAll from '@stimulus-components/checkbox-select-all'

export default class extends CheckboxSelectAll {
  connect () {
    super.connect()
  }

  sendRequestWithCheckedIds (event) {
    event.preventDefault()
    if (this.checked.length === 0) return

    const url = event.currentTarget.getAttribute('href')
    this.sendPostRequest(url)
  }

  async sendPostRequest (url) {
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: this.requestHeaders(),
        body: JSON.stringify({ email_ids: this.checkedIds() })
      })

      if (!response.ok) throw new Error('Request failed')
      window.location.reload()
    } catch (error) {
      console.error('Request failed:', error)
    }
  }

  requestHeaders () {
    return {
      'Content-Type': 'application/json',
      'X-CSRF-Token': this.getMetaValue('csrf-token')
    }
  }

  checkedIds () {
    return this.checked.map(input => input.value)
  }

  getMetaValue (name) {
    const element = document.head.querySelector(`meta[name="${name}"]`)
    return element.getAttribute('content')
  }
}
