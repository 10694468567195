import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['item']

  connect () {
    console.log('Carousel connected')
    this.index = 0
    this.showCurrentSlide()
  }

  prev () {
    this.index = (this.index - 1 + this.itemTargets.length) % this.itemTargets.length
    this.showCurrentSlide()
  }

  next () {
    this.index = (this.index + 1) % this.itemTargets.length
    this.showCurrentSlide()
  }

  showCurrentSlide () {
    this.itemTargets.forEach((item, i) => {
      item.classList.toggle('hidden', i !== this.index)
      item.dataset.active = i === this.index
      // lazy load image
      if (i === this.index) {
        item.querySelector('img').src = item.querySelector('img').dataset.target
      }
    })
  }
}
