import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['input', 'status']

  copy () {
    navigator.clipboard.writeText(this.inputTarget.value)
      .then(() => {
        this.statusTarget.classList.remove('hidden')
        setTimeout(() => {
          this.statusTarget.classList.add('hidden')
        }, 2000)
      })
      .catch(err => {
        console.error('Failed to copy text: ', err)
      })
  }
}
