import { Controller } from "@hotwired/stimulus"
import { get } from '@rails/request.js'

export default class extends Controller {
  static values = { url: String, uuid: String }

  connect() {
    this.loadPeople()
  }

  loadPeople() {
    const params = new URLSearchParams()

    params.append('list_id', this.uuidValue)

    get(`${this.urlValue}?${params}`, {
      responseKind: 'turbo-stream'
    })
  }
}
