// Entry point for the build script in your package.json
import { Turbo } from '@hotwired/turbo-rails'
import 'trix'
import '@rails/actiontext'
import './controllers'
import Alpine from 'alpinejs'
import { StreamActions } from '@hotwired/turbo'
import * as echarts from 'echarts'
import 'echarts/theme/dark.js'
import '@oddcamp/cocoon-vanilla-js'

window.echarts = echarts
window.Alpine = Alpine
Alpine.start()
// import { Sortable } from 'sortablejs'

// <turbo-stream action="redirect" url="url_here"></turbo-stream>
//
StreamActions.redirect = function () {
  // console.log(this);
  window.location.href = this.getAttribute('url')
}

Turbo.setConfirmMethod((message) => {
  return new Promise((resolve) => {
    const dialog = document.getElementById('custom-confirm-dialog')
    const messageElement = document.getElementById('custom-confirm-message')
    const yesButton = document.getElementById('custom-confirm-yes')
    const noButton = document.getElementById('custom-confirm-no')
    const overlay = document.getElementById('custom-confirm-overlay')

    if (!dialog || !messageElement || !yesButton || !noButton || !overlay) {
      console.error('Custom confirm dialog elements are missing.')
      resolve(false)
      return
    }

    message && (messageElement.textContent = message)
    dialog.classList.remove('hidden')
    setTimeout(() => {
      dialog.classList.add('opened')
    }, 1)

    const onYesClick = () => handleResponse(true)
    const onNoClick = () => handleResponse(false)
    const onOverlayClick = () => handleResponse(false)

    yesButton.addEventListener('click', onYesClick, { once: true })
    noButton.addEventListener('click', onNoClick, { once: true })
    overlay.addEventListener('click', onOverlayClick, { once: true })

    const handleResponse = confirmed => {
      dialog.classList.remove('opened')
      setTimeout(() => {
        dialog.classList.add('hidden')
      }, 200)
      yesButton.removeEventListener('click', onYesClick)
      noButton.removeEventListener('click', onNoClick)
      overlay.removeEventListener('click', onOverlayClick)
      resolve(confirmed)
    }

    const handleEscape = (event) => {
      if (event.key === 'Escape') {
        handleResponse(false)
        window.removeEventListener('keydown', handleEscape)
      }
    }

    window.addEventListener('keydown', handleEscape)
  })
})
