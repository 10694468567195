// app/javascript/controllers/selectall_controller.js
import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['checkbox']
  connect () {
    this.selectAll = false
  }

  toggleselectAll () {
    this.selectAll = !this.selectAll
    const checkBoxes = document.querySelectorAll('.permission-checkbox')
    console.log(this.selectAll)
    checkBoxes.forEach((checkBox) => {
      checkBox.checked = this.selectAll
    })
  }

  togglesecondaryselectAll () {
    this.selectAll = !this.selectAll
    const checkBoxes = document.querySelectorAll('.secondary-permission-checkbox')
    console.log(this.selectAll)
    checkBoxes.forEach((checkBox) => {
      checkBox.checked = this.selectAll
    })
  }
}
