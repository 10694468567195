import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  updateText (event) {
    // Get the value of the data-id attribute
    const dataId = event.target.getAttribute('data-id')

    // Find the corresponding text element using the data-id
    const textElement = document.querySelector(`.${dataId}Text`)

    // Update the text of the found element with the current value of the input field
    if (textElement) {
      textElement.textContent = event.target.value
    }
  }
}
