import { Controller } from '@hotwired/stimulus'
import { get } from '@rails/request.js'

export default class extends Controller {
  static targets = ['select', 'startDate', 'endDate', 'labels', 'owner', 'participants']

  static values = {
    url: String,
    param: String,
    field: String,
    target: String,
    uuid: String,
    recordId: Number
  }

  connect () {
    console.log('select connected')
  }

  change (event) {
    const params = new URLSearchParams()

    params.append(this.paramValue, event.target.selectedOptions[0].value)
    params.append('field', this.fieldValue)
    params.append('target', this.targetValue)
    params.append('uuid', this.uuidValue)
    params.append('record_id', this.recordIdValue)

    get(`${this.urlValue}?${params}`, {
      responseKind: 'turbo-stream'
    })
  }

  toggle (event) {
    const isChecked = event.target.checked
    const selectElement = this.selectTarget
    const ownerElement = this.ownerTarget
    const dateElements = document.getElementsByClassName('flatpickr-input')
    const labelsElement = document.querySelector('[data-select-target="labels"]').tomselect.wrapper
    const participantsElement = document.querySelector('[data-select-target="participants"]').tomselect.wrapper

    if (isChecked) {
      selectElement.style.display = 'none'
      labelsElement.style.display = 'none'
      ownerElement.style.display = 'none'
      participantsElement.style.display = 'none'
      for (let i = 0; i < dateElements.length; i++) {
        dateElements[i].style.display = 'none'
      }
    } else {
      selectElement.style.display = 'block'
      labelsElement.style.display = 'block'
      ownerElement.style.display = 'block'
      participantsElement.style.display = 'block'
      for (let i = 0; i < dateElements.length; i++) {
        dateElements[i].style.display = 'block'
      }
    }
  }
}
