import { Controller } from '@hotwired/stimulus'
import hljs from 'highlight.js/lib/core'
import xml from 'highlight.js/lib/languages/xml'

hljs.registerLanguage('html', xml)

export default class extends Controller {
  static targets = ['textarea', 'output']

  connect () {
    this.highlight()
  }

  highlight () {
    const code = this.textareaTarget.value
    this.outputTarget.innerHTML = hljs.highlight(code, { language: 'html' }).value
  }

  update () {
    this.highlight()
  }
}
