import { Controller } from '@hotwired/stimulus'
import { get, post } from '@rails/request.js'
import TomSelect from 'tom-select'

export default class extends Controller {
  static values = {
    placeholder: String,
    target: String,
    url: String,
    param: { type: String, default: 'name' },
    createUrl: String,
    disabledAfterNew: Boolean,
    dropdownParent: String
  }

  connect () {
    if (this.element.tagName === 'INPUT' && this.element.type === 'text') {
      this.initializeTextField()
    } else if (this.element.tagName === 'SELECT') {
      this.initializeSelectField()
    } else {
      console.warn('Unsupported element type')
    }
  }

  initializeTextField () {
    this.tomSelectInstance = new TomSelect(this.element, {
      persist: false,
      create: true,
      createOnBlur: true,
      delimiter: ',',
      plugins: ['remove_button'],
      hideSelected: true,
      render: {
        no_results: () => ''
      }
    })
  }

  initializeSelectField () {
    const allowMultiple = this.element.multiple
    this.multiSelectField = new TomSelect(this.element, {
      closeAfterSelect: true,
      placeholder: this.placeholderValue || 'Search',
      plugins: allowMultiple ? ['remove_button'] : [],
      persist: true,
      dropdownParent: this.dropdownParentValue ? document.querySelector(this.dropdownParentValue) : null,
      render: {
        no_results: (data, escape) => {
          const button = document.createElement('button')
          button.setAttribute('type', 'button')

          if (!this.urlValue && !this.createUrlValue) {
            button.textContent = `No results found for "${escape(data.input)}"`
          } else {
            button.textContent = `Add new "${escape(data.input)}"`
          }

          button.onclick = async () => {
            if (this.createUrlValue !== undefined && this.createUrlValue !== '') {
              // Handle creating new record via POST (FormData)
              const formData = new FormData()
              formData.append(this.paramValue, data.input)

              const response = await post(this.createUrlValue, {
                body: formData,
                headers: { 'X-Requested-With': 'XMLHttpRequest' }
              })

              if (response.ok) {
                const newObject = await response.json
                this.multiSelectField.addOption({ value: newObject.id, text: newObject.name })
                this.multiSelectField.addItem(newObject.id)
                this.multiSelectField.refreshOptions(false)
                this.multiSelectField.control_input.value = ''
              } else {
                const newObject = await response.json
                window.alert(`Error: ${newObject.error}`)
              }
            } else if (this.urlValue !== undefined && this.urlValue !== '') {
              const params = new URLSearchParams()
              if (this.targetValue) {
                params.append('target', this.targetValue)
              }

              get(`${this.urlValue}?${params}`, { responseKind: 'turbo-stream' })
              this.multiSelectField.close()
              this.disabledAfterNewValue && this.multiSelectField.disable()
            }
          }

          setTimeout(() => {
            const input = this.multiSelectField.control_input
            input.removeEventListener('keydown', this.enterKeyListener)

            this.enterKeyListener = (event) => {
              if (event.key === 'Enter') {
                button.click()
                event.preventDefault()
              }
            }

            input.addEventListener('keydown', this.enterKeyListener)
          }, 100)

          return button
        }
      }
    })
  }

  disconnect () {
    this.multiSelectField.destroy()
  }
}
