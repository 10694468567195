import { Controller } from '@hotwired/stimulus'
import { Device } from '@twilio/voice-sdk'

export default class extends Controller {
  static values = { objectId: String, objectClass: String, clientId: String }
  static targets = [
    'beforeCall',
    'afterCall',
    'callButton',
    'hangupButton',
    'status',
    'dialedNumber',
    'fromNumber',
    'toNumber',
    'toPerson'
  ]

  connect () {
    this.device = null
  }

  async makeCall (event) {
    event.preventDefault()

    const fromNumber = this.fromNumberTarget.value
    const toNumber = this.toPersonTarget.value || this.toNumberTarget.value

    if (!fromNumber) {
      this.statusTarget.textContent = 'Please select a valid "From" number to initiate the call.'
      return
    }

    if (!toNumber) {
      this.statusTarget.textContent = 'Please provide a valid "To" number or select a contact to proceed with the call.'
      return
    }

    try {
      await this.setupTwilioDevice()
      const params = { To: toNumber, From: fromNumber, ObjectId: this.objectIdValue, ObjectClass: this.objectClassValue, ClientId: this.clientIdValue }

      if (this.device) {
        this.statusTarget.textContent = 'Calling ...'
        this.beforeCallTarget.classList.add('hidden')
        this.afterCallTarget.classList.remove('hidden')
        this.callButtonTarget.disabled = true
        this.dialedNumberTarget.textContent = toNumber

        const call = await this.device.connect({ params })
        call.on('accept', (call) => this.updateUIAcceptedOutgoingCall(call))
        call.on('disconnect', (call) => this.updateUIDisconnectedOutgoingCall(call))
        call.on('cancel', (call) => this.updateUIDisconnectedOutgoingCall(call))

        this.hangupButtonTarget.onclick = () => {
          this.statusTarget.textContent = 'Hangup ...'
          call.disconnect()
        }
      } else {
        this.statusTarget.textContent = 'Error setting up call'
      }
    } catch (error) {
      console.error('Error setting up Twilio device:', error)
      this.statusTarget.textContent = 'Error setting up call'
    }
  }

  async setupTwilioDevice () {
    try {
      const response = await fetch('/calls/client_token')
      const data = await response.json()
      this.device = new Device(data.token, { codecPreferences: ['opus', 'pcmu'] })

      this.device.on('registered', () => {
        console.log('Device Ready to make and receive calls!')
      })

      this.device.on('error', (error) => {
        console.log('Device Error: ' + error.message)
      })
    } catch (error) {
      console.error('Error setting up Device:', error)
      this.statusTarget.textContent = 'Error setting up call'
    }
  }

  updateUIAcceptedOutgoingCall (call) {
    this.beforeCallTarget.classList.add('hidden')
    this.afterCallTarget.classList.remove('hidden')
    this.statusTarget.textContent = 'Call in progress ...'
    this.callButtonTarget.disabled = true
  }

  updateUIDisconnectedOutgoingCall (call) {
    this.statusTarget.textContent = 'Call ended'
    this.beforeCallTarget.classList.remove('hidden')
    this.afterCallTarget.classList.add('hidden')
    this.callButtonTarget.disabled = false
  }

  disconnect () {
    if (this.device) {
      this.device.disconnect()
    }
  }
}
