import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="shortcut"
export default class extends Controller {
  connect () {
    document.addEventListener('keydown', this.handleKeyDown)
  }

  disconnect () {
    document.removeEventListener('keydown', this.handleKeyDown)
  }

  handleKeyDown = (event) => {
    if ((event.ctrlKey || event.metaKey) && event.key.toLowerCase() === 'k') {
      event.preventDefault()

      const linkElement = document.getElementById('quick-search-link')
      if (linkElement) {
        linkElement.click()
      }
    }
  }
}
