import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['select', 'label']

  connect () {
    this.updateLabel()
  }

  toggle (event) {
    event.preventDefault()
    const option = event.currentTarget
    const select = this.selectTarget
    const optionId = option.dataset.id
    const selectOption = select.querySelector(`option[value="${optionId}"]`)

    if (selectOption.selected) {
      selectOption.selected = false
      option.classList.remove('bg-blue-100', 'border-blue-500', 'text-blue-800', 'hover:bg-blue-200')
      option.classList.add('bg-white', 'border-gray-300', 'text-gray-700', 'hover:bg-gray-100')
    } else {
      selectOption.selected = true
      option.classList.remove('bg-white', 'border-gray-300', 'text-gray-700', 'hover:bg-gray-100')
      option.classList.add('bg-blue-100', 'border-blue-500', 'text-blue-800', 'hover:bg-blue-200')
    }

    this.updateLabel()
    this.dispatch('change', { detail: { selectedOptions: Array.from(select.selectedOptions) } })
  }

  updateLabel () {
    const select = this.selectTarget
    const selectedOptions = Array.from(select.selectedOptions)
    const labelText = selectedOptions.length > 0
      ? `${selectedOptions.length} use case${selectedOptions.length > 1 ? 's' : ''} selected`
      : 'Select Use Cases'

    this.labelTarget.textContent = labelText
  }
}
