// app/javascript/controllers/sortable_controller.js
import { Controller } from '@hotwired/stimulus'
import Sortable from 'sortablejs'

export default class extends Controller {
  static values = { url: String }

  connect () {
    this.sortable = Sortable.create(this.element, {
      group: 'shared',
      handle: '.sortable-card',
      onEnd: this.end.bind(this)
    })
  }

  async end (event) {
    const objId = event.item.dataset.objId
    const newcolumn = event.to.dataset.column
    const newPosition = event.newIndex

    const response = await fetch(this.urlValue, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector("[name='csrf-token']").content
      },
      body: JSON.stringify({
        obj_id: objId,
        column: newcolumn,
        position: newPosition
      })
    })

    if (!response.ok) {
      console.error('Failed to update the card position or status.')
    }
  }
}
