import { Controller } from '@hotwired/stimulus'
import { get } from '@rails/request.js'

export default class extends Controller {
  static targets = ['radio', 'option']
  static values = {
    param: String,
    target: String,
    url: String,
    uuid: String
  }

  connect () {
    this.updateSelectedClass()
  }

  select (event) {
    const selectedValue = event.currentTarget.dataset.value
    this.radioTargets.forEach((radio) => {
      if (radio.value === selectedValue) {
        radio.checked = true
      }
    })
    this.updateSelectedClass()
    this.sendRequest(selectedValue)
  }

  updateSelectedClass () {
    this.optionTargets.forEach((element) => {
      const radio = element.querySelector("input[type='radio']")
      if (radio.checked) {
        element.classList.add('active')
      } else {
        element.classList.remove('active')
      }
    })
  }

  sendRequest (selectedValue) {
    if (this.targetValue && this.urlValue && this.paramValue) {
      const params = new URLSearchParams()
      params.append(this.paramValue, selectedValue)
      params.append('target', this.targetValue)
      params.append('uuid', this.uuidValue)
      get(`${this.urlValue}?${params}`, { responseKind: 'turbo-stream' })
    }
  }
}
