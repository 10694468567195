import { Controller } from '@hotwired/stimulus'
import confetti from 'canvas-confetti'

export default class extends Controller {
  connect () {
    if (window.location.search.includes('celebrate')) {
      confetti({
        particleCount: 200,
        spread: 60,
        origin: { y: 0.6 }
      })
    }
  }

  triggerConfetti (event) {
    confetti({
      particleCount: 200,
      spread: 60,
      origin: { y: 0.6 }
    })
  }
}
