import { Controller } from '@hotwired/stimulus'
import { get } from '@rails/request.js'

export default class extends Controller {
  static targets = ['input']
  static values = { url: String }
  connect () {
    this.inputTarget.focus()
    this.timeout = null
  }

  search () {
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      if (this.inputTarget.value.trim() !== '') {
        const params = new URLSearchParams()
        params.append('query', this.inputTarget.value)
        get(`${this.urlValue}?${params}`, {
          responseKind: 'turbo-stream'
        })
      }
    }, 500)
  }
}
